<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('日志管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('用户名')" v-model="query.optName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('操作类型')" v-model="query.type" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('操作内容')" v-model="query.remark" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar14" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="edit()">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('查看')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template v-slot:tools>
      <el-button-group>
        <el-button type="success" @click="getHists()" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('日志下载'),sheetName: 'Sheet1' })" v-if="hasPermission('express:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
     </template>
  </vxe-toolbar>
  
  <div class="table-wrap">

    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="hists" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'"  >
      </vxe-table-column>
      <vxe-table-column field="type" :title="msg('操作类型')" min-width="120" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="created" :title="msg('操作时间')" min-width="120"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      >
      </vxe-table-column>
      <vxe-table-column field="username" :title="msg('账号')" min-width="120" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="optName" :title="msg('用户名')" min-width="120" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="ip" :title="msg('IP')" min-width="100" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="remark" :title="msg('操作内容')" min-width="200" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>


  <el-dialog :title="dglogTitle" :visible.sync="dialogEditVisible"  :fullscreen="isMinScreen">
    <hist-edit :hist="currHist" v-on:closeDg="closeEditDialog" ref="friendLinkEdit"/>
  </el-dialog>

</div>
</template> 

<script>
// @ is an alias to /src
import HistEdit from "./HistEdit.vue";

export default {
  name: 'friendLink',
  components: {
    HistEdit:HistEdit,
  },
  mounted:function(){
    var _this = this;
    _this.getHists();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          optName:null,
          type:null,
          remark:null,
          Date: [], 
        },
        catsPopoverVisiable1: false,
        catsPopoverVisiable2: false,
        currentPage: 1,   //分页的当前页
        hists: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        dialogEditVisible:false,
        dglogTitle:"",
        currHist:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    getHists: function(){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      this.axios.post(this.$kit.api.histList, param).then(function (result) {
        if(result.status){
          if(_this.hists.length>0)
            _this.hists.splice(0, _this.hists.length);
          
          _this.total = result.data.recordsTotal;
          var accountDatas = result.data.data;

          for(var i=0; i<accountDatas.length; i++){
            var item = accountDatas[i];

            _this.hists.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getHists();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      this.query.length = val;
      this.query.start = 0;
      this.getHists();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      this.query.start = this.query.length * (val -1);
      this.getHists();
    },
    // add(){  //添加
    //   this.dglogTitle = "添加订单";
    //   var _this = this;
    //   _this.currOrder = {
    //     id: null,
    //     content: null,
    //     seq:0
    //   };
     
    //   _this.dialogEditVisible = true;
    // },
    edit(){
      var _this = this;
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      _this.currHist = selectes[0];
      _this.dglogTitle = this.msg("查看日志");
      _this.dialogEditVisible = true;

    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getHists();
      }
      this.dialogEditVisible = false;
    },
    deleted(){  //删除
        var _this = this;
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var ids = [];
      for(var i = 0; i < selectes.length; i++){
          ids.push(selectes[i].id);
      }
      
      this.$confirm(this.msg('确定要删除该日志吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.histDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getHists();
          });
        });
    },
    handleEditDgOpen:function(){
      var _this = this;
      _this.$nextTick(() => {
        _this.$refs.productEdit.initForm();
      });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
