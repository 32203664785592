<template>
<el-form ref="form" :model="hist" label-width="120px">
    <el-row :xs="24" sm="12">
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('操作类型')">
                <el-input v-model="hist.type" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('操作时间')">
                <el-input v-model="hist.created" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('账号')">
                <el-input v-model="hist.username" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('用户名')">
                <el-input v-model="hist.optName" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="18">
            <el-form-item :label="msg('IP地址')">
                <el-input v-model="hist.ip" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24">
            <el-form-item :label="msg('操作内容')">
                <el-input type="textarea" :rows="3" v-model="hist.remark" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    
    <div style="text-align:center;">
        <!-- <el-button type="primary" size="mini" @click="onSubmit"><i class="el-icon-check"></i><span>确定</span></el-button> -->
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('确定')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'ProductEdit',
  data(){
    return{
    }
  }, 
  computed:{
  },
  props:{
        hist: Object,
  },
  mounted: function(){
       
  },
  methods:{
    onSubmit(){
        var url = null;
        if(this.notice.id==null){
            url = this.$kit.api.noticeCreate;
        }else{
            url = this.$kit.api.noticeUpdate;
        }

        var _this = this;
        this.$axios.post(url, this.notice)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    },
  }
}
</script>


<style class="scss">
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
    }
    .avatar {
        width: 64px;
        height: 64px;
        display: block;
    }

.w-e-toolbar{flex-wrap: wrap;}
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
    padding:8px; border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}
.upload-icon .el-form-item__content{display:flex;}
.upload-icon .el-form-item__content >*:last-child{margin-left:10px;}
</style>
